import React, { useImperativeHandle, useRef } from "react"
import { Link } from "gatsby"
import styles from "../components/scss/menu.module.scss"

class MobileMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
        }
    }

    render() {
        return (
            <div
                role="button"
                tabIndex="0"
                className={`${styles.menu} ${this.state.open ? `${styles.open}` : ""}`}
                onClick={() => this.close()}
                onKeyDown={() => this.close()}
            >
                <Link to="/case-studies">Case Studies</Link>
                <Link to="/writings">Writings</Link>
                <Link to="/resume">Resume</Link>
                <Link to="/contact">Contact</Link>
            </div>
        )
    }

    close() {
        this.setState({ open: false })
    }

    open() {
        this.setState({ open: true })
    }
}

export default React.forwardRef((props, ref) => {
    const menuRef = useRef()

    useImperativeHandle(ref, () => ({
        open() {
            menuRef.current.open()
        },
    }))

    return <MobileMenu ref={menuRef} {...props} />
})